@import 'variables.scss';

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: calc(100vh - 60px);
}

.container {
  padding: 10px 10px 0 10px;
  background-color: $white;

  .filterContainer {
    text-align: left;
    background-color: $white;
    display: flex;
    align-items: center;
  }

  .menu {
    background-color: $white;
    border: 1px solid $lightBorder;
    font-size: 12px;
    font-weight: 500;
    padding: 0 0.95rem;
    height: 37px;
    margin-left: 10px;

    @media (max-width: $maxSmall) {
      margin-bottom: 10px;
    }
  }

  .filterInputContainer {
    max-width: 300px;
    text-align: center;

    div svg {
      top: 11px;
    }
  }

  .filterInput {
    padding: 0.44rem 0.75rem;
    background-color: white !important;
  }

  .searchIcon {
    height: 20px;
    width: auto;
    transform: scaleX(-1);
    color: $lightPurple1;
  }

  .countryRowContainer {
    min-width: 60px;
  }

  .organizerRowContainer,
  .competitionRowContainer {
    div[draggable='false'] {
      height: 30px !important;
      width: 30px !important;
    }

    img {
      height: 30px !important;
      width: auto;
    }
  }

  .competition {
    background-color: $white;
    padding-left: 10px;

    thead tr th div {
      font-weight: 600;
      justify-content: left !important;
    }

    tbody tr td div {
      text-align: left;

      img {
        height: auto !important;
        width: 30px;
      }
    }
  }
}