@import 'variables.scss';

.loaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    min-height: calc(100vh - 60px);
}

.content {
    position: relative;
    right: 0;
    left: 0;
    top: 150px;
    box-sizing: border-box;
    margin: 0.6rem 0.6rem 0;

    @media (min-width: $minVeryLarge) {
        margin-left: auto;
        margin-right: auto;
    }

    @media (max-width: $maxMedium) {
        padding-top: 150px;
    }
}

.container {
    position: relative;
    background-color: #fff;
    right: 0;
    left: 0;
}

.title {
    color: #000000;
    padding: 20px 0 0 30px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
}

.subTitle {
    color: #000000;
    position: relative;
    left: 20px;
    padding: 0 0 0 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}

.actionBar {
    display: flex;
    padding: 0;
    align-items: center;
}

.menuInactive {
    border-right: 1px solid $white;
    background-color: $lightPurple1;
}

.menuFirstChild {
    border-radius: 8px 0 0 8px;
}

.menuLastChild {
    border-radius: 0 8px 8px 0;
}

.menuActive {
    border: 1px solid $lightPurple1;
    border-bottom-width: 1px !important;
    color: $lightPurple1;
}